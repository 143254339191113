import { authInstance } from "./instances/coindisco";

const referralCodeAPI = new (class {
  public async redeemReferralCode(referralCode: string) {
    const { data } = await authInstance.post(
      `referral-system/referral/${referralCode}/redeem/`,
    );

    return data;
  }

  public async fetchReferralCode() {
    const { data } = await authInstance.get(
      `referral-system/referral/referral-code/`,
    );

    return data.code;
  }
})();

export default referralCodeAPI;
